import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppSupplyChainRest } from './AppSupplyChainRest'

const AppSupplyChainWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SupplyChainSectionSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .SupplyChainSectionNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .SupplyChainSectionSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppSupplyChainWithNav = () => {
  return (
    <AppSupplyChainWithNavWapper>
      <div className='SupplyChainSectionSection'>
        <div className='SupplyChainSectionNav'>
          <AppSideNavEmailName />
        </div>
        <div className='SupplyChainSectionSectionContent'>
          <AppSupplyChainRest />
        </div>
      </div>
    </AppSupplyChainWithNavWapper>
  )
}
