import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOneLeft: "Because of this, according to",
    ParaOneAnchor: "BusinessWire,",
    ShiftUrlParaOne: "https://www.businesswire.com/news/home/20210920005434/en/9.65-Billion-Procurement-Software-Markets-by-Deployment-Software-Industry-Region---Global-Forecast-to-2026---ResearchAndMarkets.com",
    ParaOneRight: "the spend management software market on a global basis is forecasted to reach $9.7B by 2026. Strategically understanding this growth and the value/criticality which companies around the world are putting into digitizing procurement, the market is expected to grow 73% to support managing financial risk and transparency.",
    ParaTwo: "Raindrop empowers this move up the value chain by leveraging several factors.  Armed with automation, advanced analytics, machine learning, and artificial intelligence, Raindrop unlocks complex spend categories while cleanly feeding the downstream transactional activities.  Given their functional criticality, Supply Chain and Procurement must be integrated across all corporate functions at a strategic planning and execution level, putting each CPO into delivering peak value to their corporate leadership constituency.",
    HeadingOne: "Raindrop Enhances Your Company’s Procurement Digitization Initiatives.",
    HeadingOnePointOne: "Transform from Reactive to Predictive Sourcing",
    ParaThree: "Every company works on the principle of not being fully efficient simply because focus is not put on every issue all the time.  This turns some functions into being reactive to a given need. A perfect example is when the Procurement department cuts a Purchase Order to pay an invoice for which a good and/or service has already been provided. This is the very definition of “reactive”.  So how does Raindrop help a company change this fundamental issue and become “predictive”? First off, let’s start off with defining what “Predictive Sourcing” is.  Applying data intelligence of any given commodity to drive cohesive future outcomes is what we mean here, which are core functions of Raindrop’s system. So how does it work?  The answer focuses on deep spend analysis, category planning, collaborative executive stakeholder engagement, which ultimately design the known outcome before starting down the transformation path itself. In doing so, “Predictive Sourcing” is a transformational event that future proofs risk, fundamentally improves your enterprise value, increases supplier value, and provides clarity across all levels.  With Raindrop’s Analytics and Planning modules, your company can begin addressing any spend category and move down the path of “Predictive Sourcing”.",
    ParaFour: "Gain immediate spend visibility and advanced spend intelligence to deliver on cost reduction and supplier cost efficiency strategies, courtesy of Raindrop’s native Machine Learning and Artificial Intelligence technologies.  Move up the value chain by shifting the CPO’s Procurement organization into a “predictive sourcing” driver, proactively guiding how and where funds leave the company.  Optimize these spend categories and begin generating genuine cash from operations, in turn delivering increased margins back to your company.",
    HeadingOnePointTwo: "Digitized End-to-End Procurement Process",
    ParaFive: "In general, and especially in the Procurement and Supply Chain space, technology is transforming consistently, enabling CPOs new ways to solve their spend management challenges.  Like most organizations, you may have suppliers spend data fragmented across various point solution systems.  This creates the challenge of rapidly accessing clean holistic information to plan your future capital and operational expenses.  This is exactly where Raindrop comes in.  Raindrop’s seamlessly designed “single pane of glass” end-to-end procurement technology suite provides all organizations with a comprehensive view of their source-to-contract process, allowing companies to make more informed and data-driven decisions.",
    ParaSix: "Realize rapid value attainment to support your entire enterprise spend management digitization needs from Spend Analytics, Spend Planning, Supplier Relationship Management, Sourcing, Contract Lifecycle Management, and Purchase Orders.  Instead of data silos across multiple products, which are generally not fully integrated, Raindrop’s frictionless integration and user experience ensure your team has a single unified view of spend, driving specific actions to maximize the value of your investments.  Raindrop digitizes the complete procurement process from analyzing supplier spend down to the invoice line item, developing a sourcing strategy, carrying out contract negotiations, contract implementation, all the way through supplier performance management.",
    HeadingOnePointThree: "Advanced Analytics: Transforming Procurement Processes",
    ParaSeven: "Data integrity is the central pillar of creating holistically valuable outcomes.  In most cases, corporate spend data is derived or provided from multiple source systems, so ensuring your information is correct is the first step of the process. Integration to these various data sources helps to create a multidimensional view of internal and external spend.",
    ParaEight: "Raindrop helps to provide a holistic view across the entire corporate spend.  Spend and cost information can be combined with external data, quantitative or qualitative – such as geographical assessments, risk analyses, alternative supply sources, and financial statistics. This type of 360-degree view enables companies to make better decisions, increasing the resiliency of their supplier spend strategy.",
    ParaNine: "Raindrop encapsulates multiple types of key analytics into various reports such as consumption analytics, cost benchmarking analytics, spend analytics, bid analytics, contract analytics, supplier risk, and performance analytics.  The advanced analytics helps the Supply Chain and Procurement teams to conduct a detailed technology-driven analysis of spend data and implement changes based on specific desired conclusions to drive future performance.  This supports companies to focus on aligning and meeting corporate financial and other strategic goals.",
    HeadingOnePointFour: "Embrace CSR and D&I Initiatives as Opportunities, Not Roadblocks",
    ParaTen: "Raindrop continuously enables innovations and value streams to expand your strategic advantage.  Supporting your corporate social responsibility (CSR) program, supplier diversity and inclusion (D&I), and sustainability programs are key differentiating factors built directly into the Raindrop platform.",
    ParaEleven: "Raindrop’s Supplier module caters to supporting and addressing critically relevant diversity and inclusion aspects, from knowing who your diverse suppliers are, what type of spend is addressed by diverse suppliers, keeping track of supplier certificates, all that way to meeting customer social responsibility goals, and tracking the progress.  Define action plans, assign stakeholders, and measure results for every supplier engagement.  With Raindrop, let’s redefine how you interact to realize the most potential in supporting a diverse supplier base.",
    ParaTwelve: "Raindrop’s ability to support and drive a diverse supplier network will ensure your Diversity & Inclusion initiatives are met.  In turn, companies can now tie corporate top-level D&I strategies back to the various functional groups in a consistent manner and message to all stakeholders.  Raindrop is a highly collaborative, flexible, easy-to-use platform, enabling all internal groups to roll up to top-level corporate D&I initiatives.",
  },
]
